import React from 'react';
import { Container, Typography, Grid, CssBaseline,  Toolbar, AppBar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DrawerMenu from '../Shared/DrawerMenu';


const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      background: {
        default: '#47b3b5', // Set your desired background colour here
      },
      appbar: {
        default: '#ffffff', // Set your desired background colour here
      },
      switchColor: {
        main: '#47b3b5',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ffffff', // Ensure the body has the same background colour
          },
          secondary: {
            backgroundColor: '#47b3b5', // Ensure the body has the same background colour
          },
          switch: {
            main: '#47b3b5',
          }
        },
      },
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 6,
    p: 4,
  };
  
 

const Support = () => {
   
 
    
return (
    <ThemeProvider theme={theme}>
    <CssBaseline>
    <Container component="primary" maxWidth="xs"> 
    <AppBar position="static" color="appbar" elevation={0}>
          <Toolbar>
            <DrawerMenu />
            <Typography variant="body" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Support
            </Typography>
          </Toolbar>
        </AppBar></Container>
        <Container component="secondary" maxWidth="xs"> 

  
        <Grid item xs={12} justifyContent="center" height={3}>
    </Grid>
  
    <iframe
        src="https://www.rounda.com.au/user-faqs.html"
        title="Support Page"
        style={{ width: '100%', height: '80vh', border: 'none' }}
      />
    

    <Grid item xs={12} justifyContent="center" height={30}>
    </Grid>
    </Container>
            </CssBaseline>
            </ThemeProvider>
       
  );

}
export default Support;

export {};